<template>
  <div>
    <PageHeader
      :main-header="$t('common.maintenance')"
      main-header-color="grey"
      main-header-text-color="white--text"
    />
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
export default {
  name: 'Maintenance',

  components: {
    PageHeader
  }
}
</script>

<style scoped></style>
